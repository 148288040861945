<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :loading="loading"
    item-key="name"
  >
    <template v-slot:[`item.date`]="{ item }">
      <span>{{ item.date | formatToHuman }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { formatToHuman } from '@/utils/time'

export default {
  props: {
    data: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    addMakeField: { type: Boolean, required: true },
  },
  computed: {
    headers() {
      const baseHeaders = [
        { text: 'No.', align: 'start', sortable: false, value: 'bookingId' },
        {
          text: 'Customer',
          align: 'start',
          sortable: false,
          value: 'customerName',
        },
        {
          text: 'Reg No.',
          align: 'start',
          sortable: false,
          value: 'registrationNumber',
        },
        { text: 'Date', align: 'start', sortable: false, value: 'date' },
        {
          text: 'Phone No.',
          align: 'start',
          sortable: false,
          value: 'phoneNumber',
        },
      ]

      if (this.addMakeField) {
        baseHeaders.splice(2, 0, {
          text: 'Make & Model',
          align: 'start',
          sortable: false,
          value: 'vehicleMakeModel',
        })
      }

      return baseHeaders
    },
  },
  filters: {
    formatToHuman,
  },
}
</script>
